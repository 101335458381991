<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      الإعدادات
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        v-if="Object.keys(clinicData).length"
      >
        <b-row>
          <b-col md="10">
            <div>
              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">البيانات الشخصية</h5>
                </template>
                <b-row>
                  <b-col lg="3">
                    <img
                      :src="clinicData.personal_picture"
                      class="img-fluid rounded-circle settings-user-profile"
                    />
                  </b-col>
                  <b-col lg="9">
                    <b-row>
                      <b-col xl="6">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="clinicData.clinic_name"
                          validate="required"
                          name="name"
                          placeholder="اسم العيادة"
                          label="اسم العيادة"
                        />
                      </b-col>
                      <b-col xl="6">
                        <country-code
                          :defaultCountry="
                            clinicData.country_code === '002' ? 'eg' : 'sa'
                          "
                          validate="required|numeric"
                          placeholder="رقم الهاتف"
                          label="رقم الهاتف"
                          name="phone"
                          v-model="clinicData.primary_phone_number"
                          id="phone-code-primary_phone_number"
                          @onSelect="onSelect"
                        />
                      </b-col>
                      <b-col xl="6">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="clinicData.email"
                          :validate="'required|email'"
                          name="id"
                          placeholder="Mahmoudsayed@gmail.com"
                          label="البريد الالكترونى"
                        />
                      </b-col>
                      <b-col xl="6">
                        <country-code
                          :defaultCountry="
                            clinicData.country_code === '002' ? 'eg' : 'sa'
                          "
                          validate="numeric"
                          placeholder="رقم هاتف اخر"
                          label="رقم هاتف اخر"
                          name="phone2"
                          v-model="clinicData.secondary_phone_number"
                          id="phone-code-primary_phone_number"
                          @onSelect="onSelect2"
                        />
                      </b-col>
                      <b-col md="12">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="clinicData.clinic_address"
                          :validate="'required'"
                          name="address"
                          placeholder="ادخل عنوان العيادة"
                          label="عنوان العيادة"
                        />
                      </b-col>
                      <b-col md="12">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="ibanInput"
                          :validate="{
                            required: true,
                            regex: /^['SA']{2}[0-9]{22}$/,
                          }"
                          name="iban"
                          placeholder="ادخل ال IBAN"
                          label="رقم IBAN"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-4">
                <div class="" v-if="work_times.length">
                  <h5 class="text-primary">مواعيد العمل</h5>
                  <div class="p-4">
                    <div
                      class="d-flex align-items-center gap_1 time-of-work-item flex-wrap"
                      v-for="(timeOfWork, index) in work_times"
                      :key="index"
                    >
                      <div
                        class="time-of-work-item-controls d-flex align-items-center gap_1"
                      >
                        <span
                          class="time-of-work-item-controls-label font-size-20"
                          >{{ timeOfWork.day }}</span
                        >

                        <b-form-checkbox
                          :id="`time-of-work-item-controls-checkbox-${index}`"
                          v-model="timeOfWork.is_active"
                          :name="`time-of-work-item-controls-checkbox-${index}`"
                          class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                          :value="false"
                          :unchecked-value="true"
                          ariaDescribedby="item-checkbox-day"
                        >
                          مغلق
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="timeOfWork.is_active"
                        class="d-flex flex-column gap_1"
                      >
                        <div
                          class="d-flex align-items-center gap_4 flex-wrap"
                          v-for="(timeItem, idx) in timeOfWork.working_periods"
                          :key="idx"
                        >
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              من
                            </h6>
                            <flat-pickr
                              v-model="timeItem.from"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date"
                            />
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              الى
                            </h6>
                            <flat-pickr
                              v-model="timeItem.to"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date-to"
                            />
                          </div>
                          <b-button
                            v-if="idx === timeOfWork.working_periods.length - 1"
                            class="d-flex align-items-center d-flex align-items-center bg-transparent border-0 p-0 time-of-work-item-add-btn"
                            @click="addTimeOfWork(index)"
                          >
                            <i class="las la-clock icon"></i>
                            <span>اضافة فترة عمل اخرى</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
            <div v-if="clinicData.status === 'pending'">
              <b-row>
                <b-col
                  md="4"
                  class="mb-4"
                  v-for="(itemFile, index) in requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile"
                    :index="index"
                    @uploadDocument="uploadDocument"
                    :src="itemFile.url"
                    :backgroundImage="itemFile.url"
                  />
                </b-col>
              </b-row>
            </div>
            <div v-else-if="clinicData.status === 'processing'">
              <b-row>
                <b-col
                  md="4"
                  class="mb-4"
                  v-for="(itemFile, index) in clinicData.uploaded_documents
                    .length > 0
                    ? clinicData.uploaded_documents
                    : requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile.doc || itemFile"
                    :index="index"
                    @uploadDocument="uploadDocument"
                    :src="itemFile.url"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                variant="primary"
                class="vete-save-btn m-auto"
                type="submit"
                v-if="!loadingButtonSubmit"
              >
                حفظ
              </b-button>
              <b-button variant="primary" class="vete-save-btn m-auto" v-else>
                <spinner-loading :text="'يتم التحميل'"></spinner-loading>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="justify-content-center" v-else>
        <b-spinner label="loading..."></b-spinner>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import clinicApi from "../services/settings";
import ImgUploadBox from "../../../doctorDashboard/settings/components/imgUploadBox.vue";

export default {
  components: { ImgUploadBox },
  mounted() {
    core.index();
  },
  data() {
    return {
      loadingButtonSubmit: false,
      clinicData: {},
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user
        : {},
      work_times: [],
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: false,
        defaultDate: "14:30",
      },
      code: "",
      code2: "",
      requiredDocuments: null,
      uploadedDocuments: [],
      ibanInput: "", // Separate variable for IBAN
    };
  },
  methods: {
    addTimeOfWork(idx) {
      this.work_times[idx].working_periods.push({ from: "", to: "" });
    },
    getClinicInfo() {
      clinicApi.getClinicInfo().then((response) => {
        this.clinicData = response.data;
        this.ibanInput = this.clinicData.iban; // Initialize ibanInput
        console.log("this.clinicData", this.clinicData);
      });
    },
    getRequiredDocuments() {
      clinicApi.getRequiredDocuments().then((response) => {
        this.requiredDocuments = response.data.data;
        console.log("this.requiredDocuments", this.requiredDocuments);
      });
    },
    uploadDocument(file) {
      const fileExist = this.uploadedDocuments.find(
        (f) => f.doc_id === file.doc_id
      );
      if (fileExist) {
        const docs = this.uploadedDocuments.filter(
          (item) => item.doc_id === file.doc_id
        );
        this.uploadedDocuments = docs;
      } else {
        this.uploadedDocuments.push(file);
      }
    },
    apiCaller(payload, docs = true) {
      clinicApi
        .updateClinicInfo(payload)
        .then((response) => {
          core.showSnackbar("success", response.data.message);
        })
        .then(() => {
          if (docs) {
            clinicApi
              .updateClinicsDocuments({ documents: this.uploadedDocuments })
              .then((response) => {
                core.showSnackbar("success", response.data.message);
              });
          }
        })
        .finally(() => {
          this.loadingButtonSubmit = false;
        });
    },
    onSubmit() {
      this.loadingButtonSubmit = true;
      // handle work times
      const workOfTimes = this.work_times.map((item) => {
        if (item.is_active) {
          return item;
        } else {
          return {
            ...item,
            working_periods: [],
          };
        }
      });
      const payload = {
        clinic_name: this.clinicData.clinic_name,
        email: this.clinicData.email,
        primary_phone_number: this.clinicData.primary_phone_number,
        secondary_phone_number: this.clinicData.secondary_phone_number,
        clinic_address: this.clinicData.clinic_address,
        work_times: workOfTimes,
        IBAN: this.ibanInput, // Copy from ibanInput
      };
      if (this.clinicData.status !== "accepted") {
        if (
          (this.requiredDocuments &&
            this.requiredDocuments.length === this.uploadedDocuments.length) ||
          this.clinicData.uploaded_documents.length
        ) {
          this.apiCaller({ ...payload, status: "processing" });
        } else {
          core.showSnackbar("error", "please upload your all documents");
          this.loadingButtonSubmit = false;
        }
      } else {
        this.apiCaller(payload, false);
      }
    },
    onSelect(data) {
      this.code = data;
    },
    onSelect2(data) {
      this.code2 = data;
    },
  },
  watch: {
    clinicData(val) {
      if (val) {
        // work times
        // add initial item for inactive days
        if (val.work_times) {
          const newItems = val.work_times.map((item) => {
            if (!item.is_active) {
              if (item.working_periods.length) {
                return item;
              } else {
                return {
                  ...item,
                  working_periods: [{ to: "", from: "" }],
                };
              }
            } else {
              return item;
            }
          });
          this.work_times = newItems;
        } else {
          this.work_times = [
            {
              day: "Saturday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "sunday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Monday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Tuesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Wednesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Thursday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Friday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
          ];
        }
      }
    },
  },
  created() {
    this.getClinicInfo();
    this.getRequiredDocuments();
  },
};
</script>
